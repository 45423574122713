/** @format */

import React from "react";
import "./OwnerCard.css";

const OwnerCard = ({ ownerName, address, imageUrl, coordinates }) => {
  const mapUrl = `https://www.google.com/maps?q=${coordinates}`;

  return (
    <div className="owner-card">
      <div className="owner-info">
        <img src={imageUrl} alt={ownerName} className="owner-image" />
        <div className="owner-details">
          <h2>{ownerName}</h2>
          <p>{address}</p>
        </div>
      </div>
      <div className="owner-map">
        <a
          href={mapUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="map-link">
          <i className="fas fa-map-marker-alt gps-icon"></i>
          View on Google Maps
        </a>
      </div>
    </div>
  );
};

export default OwnerCard;
