/** @format */

import React, { useState } from "react";
import PetCard from "./PetCard";
import OwnerCard from "./OwnerCard";
import CallHoomanButton from "./CallHoomanButton";
import SendLocationButton from "./SendLocationButton";
import "./TagCards.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function TagCards({ tagID, owner, addresses, pets }) {
  const [error, setError] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });

  const handleSendLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          try {
            const response = await fetch("http://43.204.96.124/api/location", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                tagID,
                lat: latitude,
                long: longitude,
              }),
            });

            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }

            alert("GPS location sent!");
            console.log(response);
          } catch (error) {
            alert(`Error sending location: ${error.message}`);
          }
        },
        (error) => {
          setError(error.message);
          alert(`Error fetching location: ${error.message}`);
        }
      );
    } else {
      setError("Geolocation is not available in your browser.");
      alert("Geolocation is not available in your browser.");
    }
  };

  // Use default values or placeholders if no data is available
  const pet = pets[0] || {};
  const address = addresses[0] || {};
  const ownerData = owner || {};
  // Extract coordinates if available
  const coordinates = address.location?.coordinates || [];

  return (
    <div className="tag-card">
      <PetCard
        name={pet.name || "-"}
        gender={pet.gender || "-"}
        age={
          pet.dob
            ? (() => {
                const birthDate = new Date(pet.dob);
                const today = new Date();

                let years = today.getFullYear() - birthDate.getFullYear();
                let months = today.getMonth() - birthDate.getMonth();

                if (months < 0) {
                  years--;
                  months += 12;
                }

                if (today.getDate() < birthDate.getDate()) {
                  months--;
                }

                // Convert years and months to a decimal
                let ageInYears = years + months / 12;

                return `${ageInYears.toFixed(1)} years`;
              })()
            : "-"
        }
        breed={pet.breed || "-"}
        imageUrl={pet.petImageLink || "default-image-url"} // Replace with a default image URL
      />
      <OwnerCard
        ownerName={ownerData.name || "-"}
        address={`${address.houseNo || "-"}, ${address.appartment || "-"}, ${
          address.title || "-"
        }`}
        imageUrl={ownerData.profilePhotoLink || "default-owner-image-url"} // Replace with a default image URL
        coordinates={coordinates} // Pass coordinates array
      />
      <CallHoomanButton phoneNumber={ownerData.phoneNumber || "-"} />
      <SendLocationButton onClick={handleSendLocation} />
      <div className="whoWeAre">
        <img
          className="whoWeAreImage"
          src="../../images/dog-image.png"
          alt="Dog"
        />
        <div className="whoWeAreTextContainer">
          <h3>Who We Are</h3>
          <p className="whoWeAreText1">We Love Your Pet, Just as You Do!</p>
          <p className="whoWeAreText2">
            We are proud to introduce Zoop, a product of Negoteq and the world's
            first pet community app tailored for all pet enthusiasts. Zoop
            offers an interactive feed with specialized filters for Healthcare,
            Adoption, and Rescue, fostering a unified global pet community.
            Beyond a traditional social networking platform, Zoop features
            interactive community elements like Zoop Walk, motivating pet
            parents to participate in community challenges that support feeding
            and sheltering stray dogs across the nation.
          </p>
          <button className="knowMoreButton">Know More</button>
        </div>
      </div>
    </div>
  );
}

export default TagCards;
