/** @format */

import React, { useState } from "react";
import "./PetCard.css";
import vector27 from "./pages/images/vectors/Vector27_x2.svg";
import vector28 from "./pages/images/vectors/Vector28_x2.svg";

const PetCard = ({ name, gender, age, breed, imageUrl }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`pet-card ${isExpanded ? "expanded" : ""}`}>
      <div className="pet-image">
        <img src={imageUrl} alt={name} />
        <img src={vector28} alt="left decoration" className="decoration left" />
        <img
          src={vector27}
          alt="right decoration"
          className="decoration right"
        />
      </div>
      <div className="pet-details">
        <h2>{name}</h2>
        <p>
          {gender} • {age} • {breed}
        </p>
        <a href="#" className="details-link" onClick={toggleExpand}>
          {isExpanded ? "Close Details" : "More Details"}
        </a>
        {isExpanded && (
          <div className="additional-details">
            <div className="detail-item">
              <strong>Date of Birth:</strong>
              <div>26 Feb, 2024</div>
            </div>
            <div className="detail-item">
              <strong>Allergies:</strong>
              <div className="allergies">
                <span>Curd</span>
                <span>Milk</span>
                <span>Soya Chunks</span>
                <span>Sugar</span>
                <span>Apple</span>
                <span>Banana</span>
                <span>Royal Canin</span>
              </div>
            </div>
            <div className="detail-item">
              <strong>Neutered / Spayed:</strong>
              <div>Not Yet</div>
            </div>
            <div className="detail-item">
              <strong>Vaccination:</strong>
              <div className="vaccination-details">
                <div>14 Apr, 2024</div>
                <div>Day 45: Distemper, Parvo</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PetCard;
