import React from 'react';
import './SendLocationButton.css';
import locationIcon from './pages/images/vectors/location_on.svg'; // Replace with the correct path to your SVG file

const SendLocationButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="send-location-button">
      <img src={locationIcon} alt="Location" className="location-icon" />
      <div className="button-text">
        <span>Send Location</span>
      </div>
    </button>
  );
}

export default SendLocationButton;