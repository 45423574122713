/** @format */

import React from "react";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Tag from "./components/pages/tag";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact />
          <Route path="/tag" element={<Tag />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
