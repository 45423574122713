/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./footer.module.css";
import zoopLogo from "../images/Zoop logo-02.png"; // Correctly import the image

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerColumn}>
        <div className={styles.footerLogo}>
          <img src={zoopLogo} alt="Zoop Logo" className={styles.logoImage} />
        </div>
        <p className={styles.descriptionZoop}>
          Zoop is your one-stop shop for everything pet-related. Download the
          app today and join a vibrant community that’s passionate about making
          a difference in the lives of pets everywhere!
        </p>
        <div className={styles.footerSocialMedia}>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialMediaIcon}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialMediaIcon}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialMediaIcon}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.pinterest.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialMediaIcon}>
            <FontAwesomeIcon icon={faPinterestP} />
          </a>
        </div>
      </div>

      <div className={styles.footerColumn + " " + styles.contact}>
        <h3>Contact</h3>
        <p>
          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          contact@zooppets.in
        </p>
        <p>
          <FontAwesomeIcon icon={faPhone} className={styles.icon} />
          +91 72007 00369
        </p>
        <p>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          Bengaluru, Karnataka, India
        </p>
      </div>

      <div className={styles.footerColumn}>
        <h3>Quick Links</h3>
        <a href="/about">Paw Protection Fund</a>
        <a href="/services">Stray Saver Network</a>
      </div>

      <div className={styles.footerColumn}>
        <h3>Menu</h3>
        <a href="/">Home</a>
        <a href="/shop">About us</a>
        <a href="/faq">Contact us</a>
      </div>

      <p className={styles.copyright}>
        © 2024 Created with <a href="/faq">Negoteq</a>
      </p>
    </footer>
  );
};

export default Footer;
