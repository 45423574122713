/** @format */

import React, { useEffect, useState } from "react";
import "./tag.css";
import { useSearchParams } from "react-router-dom";
import path84 from "./images/vectors/Path84_x2.svg";
import path807 from "./images/vectors/Path807_x2.svg";
import path881 from "./images/vectors/Path881_x2.svg";
import path803 from "./images/vectors/Path803_x2.svg";
import path888 from "./images/vectors/Path888_x2.svg";
import path201 from "./images/vectors/Path201_x2.svg";
import TagCards from "../TagCards";

const Tag = () => {
  const [searchParams] = useSearchParams();
  const tagID = searchParams.get("tagID");
  const [tagData, setTagData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (tagID) {
      try {
        const response = await fetch(
          `http://43.204.96.124/api/get_tag/${tagID}`
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        const data = await response.json();
        setTagData(data);
        setIsDataLoaded(true);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [tagID]);

  const handleSendLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          try {
            const response = await fetch("http://43.204.96.124/api/location", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                tagID,
                lat: latitude,
                long: longitude,
              }),
            });

            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }

            alert("GPS location sent!");
            console.log(response);
          } catch (error) {
            alert(`Error sending location: ${error.message}`);
          }
        },
        (error) => {
          setError(error.message);
          alert(`Error fetching location: ${error.message}`);
        }
      );
    } else {
      setError("Geolocation is not available in your browser.");
      alert("Geolocation is not available in your browser.");
    }
  };

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="on-tag-qr-scan">
      <div className="header-container">
        <div className="frame-427322466">
          <div className="image-62" />
          <img className="path-20" src={path201} alt="Path 20" />
          <div className="container-8">
            <div className="status-bar-iphone-13-mini"></div>
            <div className="header" />
            <div className="container-2">
              <div className="container">
                <img className="path-84" src={path84} alt="Path 84" />
                <img className="path-80" src={path807} alt="Path 80" />
              </div>
              <div className="container-6">
                <img className="path-881" src={path881} alt="Path 881" />
                <img className="path-801" src={path803} alt="Path 801" />
              </div>
            </div>
            <img className="path-88" src={path888} alt="Path 88" />
          </div>
        </div>
        <div className="tag-cards-container">
          {tagData && (
            <TagCards
              owner={tagData.tag.user} // Pass user data as owner
              addresses={tagData.addresses}
              pets={tagData.pets}
            />
          )}
        </div>
        <div className="whoWeAre">{/* Your Who We Are section content */}</div>
      </div>
    </div>
  );
};

export default Tag;
