import React from 'react';
import './CallHoomanButton.css';
import phoneIcon from './pages/images/vectors/Vector4_x2.svg'; // Replace with the correct path to your SVG file

const CallHoomanButton = ({ phoneNumber }) => {
  return (
    <a href={`tel:${phoneNumber}`} className="call-hooman-button">
      <img src={phoneIcon} alt="Phone" className="phone-icon" />
      <div className="button-text">
        <span>Call my Hooman</span>
        <span>{phoneNumber}</span>
      </div>
    </a>
  );
}

export default CallHoomanButton;
